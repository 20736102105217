<template>
  <span class="deprecated-tag">
    <CyTooltip v-bind="{ [direction]: true }">
      <template #activator="{ on }">
        <div
          class="d-flex"
          v-on="on">
          <v-chip
            x-small
            class="deprecated-tag__chip mr-1">
            Deprecated
          </v-chip>
          <v-icon size="16">
            help_outline
          </v-icon>
        </div>
      </template>
      <span v-html="$sanitizeHtml(tooltipText || $t('defaultTooltipText'))"/>
    </CyTooltip>
  </span>
</template>

<script>
export default {
  name: 'CyDeprecatedTag',
  props: {
    tooltipText: {
      type: String,
      default: null,
    },
    direction: {
      type: String,
      default: 'top',
    },
  },
  i18n: {
    messages: {
      en: {
        defaultTooltipText: 'This feature is deprecated and will be removed soon.',
      },
      es: {
        defaultTooltipText: 'Esta funcionalidad está obsoleta y se eliminará pronto.',
      },
      fr: {
        defaultTooltipText: 'Cette fonctionnalité est obsolète et sera bientôt supprimée.',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .deprecated-tag {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;

    &__chip {
      background-color: cy-get-color("warning", "light-2") !important;
      color: cy-get-color("warning", "dark-2");
    }
  }

  .v-icon {
    color: cy-get-color("grey", "dark-1");
  }
</style>
